.cosponsor-item {
  .shop-img {
    background-color: $white;
  }
}

.sponsor-item {
  .shop-img {
    background-color: $red;
  }
}

.sponsor-content {
  padding: 0 10% !important;
  p.prod-title {
    border-width: 2px;
  }
}

.main-sponsor {
  margin-top: 1em;
  > a {
    display: block;
    text-align: center;
    img {
      width: 700px;
      height: auto;
    }
  }
  .sponsor-content {
    text-align: center;
    margin-top: 1.6em;
    padding: 0 20% !important;
    @include font-size(1.25,2.1875);
    p {
      border-bottom: 2px solid $red;
      padding-bottom: .2em;
      margin-bottom: .2em;
      &:last-child {
        border: none;
      }
    }
  }
}