.reveal {
  padding: 0;
  .reveal-content {
    padding: 2em 6em;
  }
}


#videoreveal {
  border-radius: 0;
  height: auto;
  .livevideo-content {
    .responsive-embed {
      margin: 0;
    }
  }
}