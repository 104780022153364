#menu-offcanvas {
  margin: 0 auto;
  width: rem-calc(820);
  max-width: 100%;
  padding: rem-calc(100 20 10 20);
  .menu {
    font-weight: bold;
    text-transform: uppercase;
    li {
      a {
        color: white;
        padding-left: 0;
        @include font-size(2,5.125);
        &::after {
          //remove default
          border: none;
        }
      }
      .submenu {
        margin-bottom: 2em;
        li {
          a {
            border-bottom: 2px solid $white;
            @include font-size(1.125,2.5);
            &::after {
              //remove default
              border: none;
              //add own "arrow"
              position: absolute;
              right: 0;
              top: 18px;
              width: 22px;
              height: 9px;
              z-index: 2;
              background-color: $white;
              /*
              using mask to change background image svg color with background-color
              */
              //maybe need to add -webkit to every mask property to function in all browsers!?
              //-webkit-mask-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 44 27" style="enable-background:new 0 0 44 27; fill:white;" xml:space="preserve"><path class="st0" d="M43.5,3.3l-20,23.1C23.1,26.7,22.6,27,22,27c-0.6,0-1.1-0.3-1.5-0.7L0.5,3.3c-0.7-0.8-0.6-2.1,0.2-2.8 c0.8-0.7,2.1-0.6,2.8,0.2L22,22L40.5,0.7c0.7-0.8,2-0.9,2.8-0.2C44.1,1.2,44.2,2.4,43.5,3.3z"/></svg>');
              //mask-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28 17" style="enable-background:new 0 0 28 17; fill:white;" xml:space="preserve"><path d="M2.1,0c0.6,0,1.1,0.2,1.5,0.6L14,11.1L24.4,0.6C24.8,0.2,25.3,0,25.9,0s1.1,0.2,1.5,0.6S28,1.5,28,2.1 c0,0.6-0.2,1.1-0.6,1.5L14,17L0.6,3.6C0.2,3.2,0,2.7,0,2.1s0.2-1.1,0.6-1.5C1,0.2,1.5,0,2.1,0z"/></svg>');
              mask-image: url('data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 44 27" style="enable-background:new 0 0 44 27; fill:white;" xml:space="preserve"><path class="st0" d="M43.5,3.3l-20,23.1C23.1,26.7,22.6,27,22,27c-0.6,0-1.1-0.3-1.5-0.7L0.5,3.3c-0.7-0.8-0.6-2.1,0.2-2.8 c0.8-0.7,2.1-0.6,2.8,0.2L22,22L40.5,0.7c0.7-0.8,2-0.9,2.8-0.2C44.1,1.2,44.2,2.4,43.5,3.3z"/></svg>');
              //-webkit-mask-size: 16px 10px;
              mask-size: 22px 9px;
              mask-repeat: no-repeat;
              mask-position: center center;
              transition: all 0.2s ease-in-out;

              @include breakpoint(large) {
                top: 24px;
                width: 33px;
                height: 18px;
                mask-size: 33px 18px;
              }

              &[aria-expanded="true"] {
                transform: rotate(180deg);
              }
              &:hover {
                opacity: 0.6;
              }
            }
          }
          ul {
            li {
              padding-left: 27%;
              a {

              }
            }
          }
        }
      }

      &.active {
        > a {
          background: transparent;
          color: white;
          &::after {
            background-color: white;
          }
        }
      }
    }
  }
}