.resultate {
  position: relative;
  .slide {

  }
}

.resultbox {
  text-align: center;
  position: relative;
  padding: rem-calc(0 20);
  @include breakpoint(medium) {
    padding: rem-calc(0 40);
  }
  @include breakpoint(large) {
    padding: rem-calc(0 60);
  }
  @include breakpoint(1300) {
    padding: rem-calc(0 80);
  }
  .main-result {
    position: relative;
    border-radius: 50%;
    border: 10px solid $red;
    width: 85%;
    aspect-ratio: 1;
    display: block;
    margin: 0 auto 30px auto;
    overflow: hidden;
    > div {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translateY(-51%);
      font-weight: bold;
      font-size: 100px;
      @include breakpoint(large) {
        font-size: 120px;
      }
    }
  }
  p {
    border-bottom: 1px solid $red;
    margin-bottom: rem-calc(5);
    padding-bottom: rem-calc(5);
  }
}