.slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  z-index: 2;
  cursor: pointer;
  &.slick-prev {
    left: 0;
    transform: rotate(180deg);
    @include breakpoint(1600) {
      left: -50px;
    }
  }
  &.slick-next {
    right: 0;
    @include breakpoint(1600) {
      right: -50px;
    }
  }
}


.logo-slider {
  margin-bottom: rem-calc(30);
  img {
    height: 120px;
    width: auto !important;
    opacity: 0.5 !important;
    max-width: none;
    margin: 0 5px;
  }
  .slick-arrow {
    display: none !important;
  }
}