@import "settings";

$input-disabled-bg: $gray;
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap-vue/src/components/calendar/_calendar.scss";

.competitions-wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: space-between;
  color: $red;
  flex-wrap: wrap;
  gap: 30px;
  & > div {
    flex-grow: 1;
    background-color: #fff;
    border-radius: 25px;
    width: 100%;
    padding: 30px;
    max-width: 600px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .competitions-calendar-header,
  .competitions-list-header {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid $black;
    div {
      font-size: min(5vw, 60px);
      font-weight: bold;
      line-height: 1em;
      display: inline-flex;
    }
  }

  .competitions-calendar {
    text-align: center;
    &-header {
      align-items: center;
      justify-content: center;
      position: relative;
      div {
        text-transform: uppercase;
      }
      button {
        position: absolute;
        background: transparent;
        border: none;
        width: min(5vw, 50px);
        height: min(5vw, 50px);
        background-size: min(5vw, 50px);
        transition: left 0.25s, right 0.25s, opacity 0.25s;
        &:first-child {
          left: 10px;
          background: url('/assets/app/images/calendar/arrow-left.svg') left center no-repeat;
        }
        &:last-child {
          right: 10px;
          background: url('/assets/app/images/calendar/arrow-right.svg') right center no-repeat;
        }
        &:not([disabled]) {
          cursor: pointer;
          &:hover {
            filter: grayscale(1);
          }
        }
        &[disabled] {
          opacity: 0.5;
        }
      }
    }
    .b-calendar {
      &-header,
      &-nav,
      &-grid-caption,
      &-grid-weekdays {
        display: none !important;
      }
      &-grid-body {
        [aria-hidden="true"] {
          .btn {
            display: none;
          }
        }
        .btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: min(4vw, 35px) !important;
          font-feature-settings: "lnum" 1;
          padding: min(2.8vw, 28px) !important;
          line-height: 0.75em !important;
          font-weight: bold;
          transition: background-color 0.25s, color 0.25s, border-color 0.25s;
          &:not(.disabled) {
            border: 2px solid $black;
            border-radius: 100%;
            cursor: pointer;
            &:hover {
              background-color: rgba($red, 0.1);
            }
          }
          &.active {
            background: $red !important;
            color: $white;
            border-color: $red;
          }
        }
      }
    }
  }

  .competitions-list {
    &-header {
      // align-items: flex-start;
      justify-content: space-between;
      button {
        background: transparent;
        border: none;
        width: min(5vw, 50px);
        height: min(5vw, 50px);
        min-width: min(5vw, 50px);
        margin-left: min(5vw, 50px);
        display: block;
        cursor: pointer;
        background: url('/assets/app/images/calendar/times.svg') center no-repeat;
        background-size: min(5vw, 50px);
        &:hover {
          filter: grayscale(1);
        }
      }
    }
    &-body {
      position: relative;
      & > div {
        display: flex;
        flex-direction: row;
        color: $black;
        border-bottom: 2px solid $black;
        padding: 10px 0;
        font-size: min(3.5vw, 20px);
        transition: all 0.25s;
        &:first-child {
          margin-top: -20px;
        }
        & > div {
          &:first-child {
            font-weight: bold;
            color: $red;
            margin-right: 20px;
          }
          &:last-child {
            margin-left: 20px;
          }
        }
        .title {
          flex: 3;
        }
        .time {
          flex: 1;
        }
        .location {
          flex: 1;
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    & > div {
      max-width: 48%;
    }
    .b-calendar {
      &-grid-body {
        .btn {
          border-width: 3px !important;
        }
      }
    }
  }
}

// Transitions
.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.25s
  }
  &-enter,
  &-leave-active {
    opacity: 0
  }
}

.slide-fade {
  &-enter-active,
  &-leave-active {
    transition: 0.25s ease-out;
  }
  &-enter {
    transform: translateY(-15px);
    opacity: 0;
  }
  &-leave-to {
    transform: translateY(15px);
    opacity: 0;
  }
  &-leave-active {
     // position: absolute !important;
  }
}

.slide-fade--left,
.slide-fade--right {
  &-enter-active,
  &-leave-active {
    background: transparent;
  }
}
.slide-fade--left-enter,
.slide-fade--right-leave-to {
  transform: translateX(-15px);
  opacity: 0;
}
.slide-fade--left-leave-to,
.slide-fade--right-enter {
  transform: translateX(15px);
  opacity: 0;
}
.slide-fade--left,
.slide-fade--right {
  &-leave-active {
    position: absolute !important;
    width: 100%;
  }
}
