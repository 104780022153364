.hero {
  width: 100%;
  height: 90vh;
  position: relative;
  @include breakpoint(medium) {
    height: 100vh;
    overflow: hidden;
  }
  &::before {
    content: "";
    background-color: $red;
    width: 1000px;
    height: 1000px;
    position: absolute;
    top: -980px;
    left: 50%;
    transform: translateX(-50%) rotate(-15deg);
    @include breakpoint(medium) {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1314 355' style='enable-background:new 0 0 1314 355;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF0000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1314,0v2.8L0,355V0L1314,0z'/%3E%3C/svg%3E%0A");
      width: 68%;
      height: auto;
      top: 0;
      left: 0;
      background-color: transparent;
      z-index: 1;
      transform: none;
    }
  }
  &::after {
    @include breakpoint(medium) {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 960 260' style='enable-background:new 0 0 960 260;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FF0000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M960,0v260H0v-2.1L960,0z'/%3E%3C/svg%3E");
      width: 50%;
      height: auto;
      right: 0;
      bottom: 0;
      z-index: 1;
      margin-bottom: rem-calc(-10);
      position: absolute;
    }
  }
  img:not(.home-logo):not(.home-logo-small) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.m-hero-overlay {
  background-color: $red;
  width: 1000px;
  height: 170px;
  position: relative;
  top: -85px;
  left: 50%;
  transform: translateX(-50%) rotate(-15deg);
  @include breakpoint(medium) {
    display: none;
  }
}

.team .hero {
  height: 80vh;
  @include breakpoint(medium) {
    height: 100vh;
  }
  img:not(.home-logo):not(.home-logo-small) {
    object-position: top center;
  }
}