table {
  @include font-size(0.9375,1.25);
  thead {
    background: transparent;
    border-bottom: 2px solid $black;
    th {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  tbody {
    tr {
      td {
        border-bottom: 2px solid $black;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.bg-red {
  table {
    thead {
      border-color: $white;
    }
    tbody {
      tr {
        td {
          border-color: $white;
          a {
            color: $white;
          }
        }
      }
    }
  }
}

.playertable {
  @include font-size(0.9375,1.5625);
  tbody {
    tr:not(.coach) td {
      border-color: $red;
    }
  }
}

table.ressorttable {
  //@include font-size(0.9375,1.5625);
  thead {
    color: $white;
  }
}

.trainingtable {
  @include font-size(1.25,2.1875);
  tbody {
    tr td {
      &:first-child {
        font-weight: bold;
      }
      @include breakpoint(small down) {
        display: block;
        text-align: center;
        &:last-child {
          margin-bottom: 1.5em;
        }
      }
    }
  }
}

.downloadtable {
  tbody {
    tr td {
      @include font-size(1,2.1875);
      &:first-child {
        font-weight: bold;
      }
      &:last-child {
        text-align: right;
        img {
          width: rem-calc(35);
          height: rem-calc(35);
          @include breakpoint(large) {
            width: rem-calc(43);
            height: rem-calc(43);
          }
        }
      }
    }
  }
}