.news-teaser {
  cursor: pointer;
  border-radius: 1em;
  overflow: hidden;
  background-color: $red;
  margin-bottom: rem-calc(20);
  .news-box-content {
    color: $white;
    padding: rem-calc(20);
    h3 {
      @include font-size(1.25,1.5625);
    }
    p {
      margin-bottom: 0.5em;
    }
  }
}

.news-detail {
  .news-date {
    color: $red;
  }
  h1 {
    color: $red;
    text-transform: none;
    text-align: left;
    @include font-size(1.25,1.5625);
    margin-top: 0;
    margin-bottom: 2em;
  }
  p {
    @include font-size(1,1.25);
  }
}