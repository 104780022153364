.contact-txt-1 {
  padding-bottom: rem-calc(20);
  margin-bottom: rem-calc(20);
  border-bottom: 2px solid $red;
  @include breakpoint(medium) {
    padding: rem-calc(0 5 0 0);
    margin-right: rem-calc(5);
    margin: 0;
    border: none;
    border-right: 4px solid $red;
  }
  @include breakpoint(large) {
    padding: rem-calc(0 10 0 0);
    margin-right: rem-calc(10);
  }
}
.contact-txt-2 {
  padding-bottom: rem-calc(20);
  margin-bottom: rem-calc(20);
  border-bottom: 2px solid $red;
  @include breakpoint(medium) {
    padding: rem-calc(0 5 0 5);
    margin: 0;
    border: none;
  }
  @include breakpoint(large) {
    padding: rem-calc(0 10 0 10);
  }
}
.contact-txt-3 {
  padding-bottom: rem-calc(20);
  margin-bottom: rem-calc(20);
  @include breakpoint(medium) {
    padding: rem-calc(0 0 0 5);
    margin: 0;
    border-left: 4px solid $red;
  }
  @include breakpoint(large) {
    padding: rem-calc(0 0 0 10);
  }
}