// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }

.bg-red {
  background-color: $red;
  color: $white;
}

.full-link {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 1;
}