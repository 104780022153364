.shopbox {
  text-align: center;
  position: relative;
  margin-bottom: 2em;
  .shop-img {
    border-radius: 50%;
    background-color: #DBDBDB;
    width: 80%;
    display: block;
    margin: 0 auto 1.6em auto;
    overflow: hidden;
    img {
      //filter: grayscale(100%);
    }
  }
  .shop-content {
    padding: 0 22%;
  }
  p {
    margin-bottom: rem-calc(5);
    padding-bottom: rem-calc(5);
    &.prod-title {
      border-bottom: 4px solid $red;
      color: $red;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .shop-price {
    background-color: $red;
    border-radius: 50%;
    width: rem-calc(100%);
    height: rem-calc(100%);
    color: $white;
    font-weight: bold;
    position: absolute;
    left: 2%;
    top: 2%;
    transform: rotate(-15deg);
    font-size: 26px;
    line-height: 1.1;
    padding: 5%;
  }
}

.bg-red {
  .shopbox {
    p {
      &.prod-title {
        border-color: $white;
        color: $white
      }
    }
  }
}