.navi-icons {
  position: fixed;
  top: rem-calc(20);
  left: 0;
  right: 0;
  z-index: 3;
  transition: all .2s ease-in-out;
  @include breakpoint(medium) {
    top: rem-calc(110);
    left: rem-calc(40);
    right: auto;
  }
  a {
    display: block;
    width: rem-calc(60);
    height: rem-calc(60);
    background-color: $white;
    border-radius: 50%;
    box-shadow: $boxshadow;
    .icon {
      transition: all .15s ease-in-out;
      fill: $red;
    }
    &:hover {
      .icon {
        fill: $black;
      }
    }
    &.home-link {
      padding: rem-calc(7 11);
      font-size: 1.3em;
      margin-bottom: rem-calc(10);
      @include breakpoint(small down) {
        position: absolute;
        right: rem-calc(20);
      }
    }
    &.video-link {
      padding: rem-calc(12 16);
      font-size: 1.1em;
      @include breakpoint(small down) {
        position: absolute;
        left: 50%;
        transform: translateX(-30px);
      }
    }
  }
}
