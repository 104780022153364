section {
  padding-bottom: 6em;
}

.uneven-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .uneven-box-item {
    flex: 0 0 100%;
    @include breakpoint(medium) {
      flex: 0 0 calc(50% - 20px);
    }
    @include breakpoint(large) {
      flex: 0 0 calc(33.33% - 20px);
    }
    margin: 10px;

    &.box-item-4 {
      flex: 0 0 calc(50% - 20px);
      @include breakpoint(medium) {
        flex: 0 0 calc(33% - 20px);
      }
      @include breakpoint(large) {
        flex: 0 0 calc(25% - 20px);
      }
    }
    &.box-item-5 {
      flex: 0 0 calc(50% - 20px);
      @include breakpoint(medium) {
        flex: 0 0 calc(25% - 20px);
      }
      @include breakpoint(large) {
        flex: 0 0 calc(20% - 20px);
      }
    }

  }

}