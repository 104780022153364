.cookieconsent {
  position: fixed;
  top: auto !important;
  bottom: 0;
  left: 0;
  right: 0;
  background: $red;
  color: $white;
  padding: rem-calc(10);
  z-index: 99999999 !important;
  border-radius: $global-radius;
  box-shadow: 0 2px 8px rgba($black,0.4);
  @include breakpoint(medium) {
    right: rem-calc(10);
    bottom: rem-calc(10);
    width: rem-calc(400);
    left: auto;
    border: 1px solid $white;
  }
  p {
    margin: 0;

  }
  a {
    color: $white;
    &:hover {
      color: rgba($white,.8);
    }
  }
  .button {
    font-size: 0.8rem;
    background: transparent;
    border-color: $white;
    color: $white;
    margin: rem-calc(10) auto;
    display: block;
    &:hover {
      background: rgba($white,0.2);
    }
  }
}
