@import "settings";

.rangliste-wrapper {
  table {
    td {
      &:first-child,
      &:last-child {
        color: $red;
      }
    }
  }
}