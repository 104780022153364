//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600,700&display=swap');

@font-face {
  font-family: 'Raleway';
  src: url('font/Raleway-SemiBold.woff2') format('woff2'),
  url('font/Raleway-SemiBold.woff') format('woff'),
  url('font/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('font/Raleway-Bold.woff2') format('woff2'),
  url('font/Raleway-Bold.woff') format('woff'),
  url('font/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('font/Raleway-Regular.woff2') format('woff2'),
  url('font/Raleway-Regular.woff') format('woff'),
  url('font/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


main {
  //Dynamic Font Size
  @include font-size(1,1.25);
}

a:focus {
  outline: none;
}

h1, h2 {
  text-align: center;
  margin: 1.8em 0 1.2em 0;
  text-transform: uppercase;
  hyphens: auto;
  @include font-size(3.125,3.75);
}

.font-big {
  @include font-size(1.1875,2.1875);
  margin-bottom: 2em;
  font-weight: $semibold;
}

.text-bold {
  font-weight: bold;
}

.address {
  @include font-size(1.25,2.1875);
  font-weight: $semibold;
}