.playerbox {
  text-align: center;
  position: relative;
  margin-bottom: rem-calc(40);
  .player-img {
    border-radius: 50%;
    border: 1px solid $red;
    border-width: var(--circle-border);
    width: 80%;
    display: block;
    margin: 0 auto 20px auto;
    overflow: hidden;
    img {
      //filter: grayscale(100%);
    }
  }
  p {
    border-bottom: 1px solid $red;
    margin-bottom: rem-calc(5);
    padding-bottom: rem-calc(5);
  }
  &.coach {
    .player-img {
      border-color: $black;
    }
    p {
      border-color: $black;
    }
  }
}