.logo {
  padding: rem-calc(20);
  a {
    display: inline-block;
    img {
      width: 200px;
      height: auto; //immer width und height in PX angeben (IE)
    }
  }
}

.hero img.home-logo {
  position: absolute;
  top: 28vh;
  left: -5%;
  width: 110%;
  height: auto;
  z-index: 1;
  max-width: none;
  //transform: translateY(-50%);
  @include breakpoint(medium) {
    top: 5%;
    left: 50%;
    height: 100vh;
    transform: translateX(-50%);
  }
}

.hero img.home-logo-small {
  position: absolute;
  bottom: 150px;
  right: -180px;
  width: 350px;
  height: auto;
  z-index: 1;
  max-width: none;
  @include breakpoint(medium) {
    width: 60vw;
    right: -20%;
    bottom: 20%;
  }
}