.history-date {
  width: rem-calc(200);
  height: rem-calc(200);
  border-radius: 50%;
  border: 10px solid $red;
  font-weight: bold;
  font-size: rem-calc(90);
  line-height: 0.8;
  padding: 14px 30px;
  display: inline-block;
  margin-bottom: rem-calc(30);
  font-feature-settings: "lnum" 1;
  text-align: center;
}

.history-txt {
  h4 {
    @include font-size(1,1.25);
    font-weight: bold;
    color: $red;
    margin-bottom: rem-calc(5);
  }
}
