.button {
  font-weight: bold;
  @include font-size(1,1.25);
  border-radius: 1.6em;
  padding: rem-calc(14p 25);
  min-width:  rem-calc(220);
}

.next-slide {
  width: rem-calc(60);
  height: rem-calc(60);
  border-radius: 50%;
  background-color: $white;
  position: absolute;
  right: 50%;
  bottom: rem-calc(-30);
  z-index: 2;
  cursor: pointer;
  font-size: 1.05em;
  padding-top: 5px;
  padding-left: 1px;
  box-shadow: $boxshadow;
  transform: translateX(50%);
  @include breakpoint(medium) {
    width: rem-calc(80);
    height: rem-calc(80);
    right: rem-calc(40);
    bottom: rem-calc(40);
    transform: none;
  }
  .icon {
    fill: $red;
    transition: all .15s ease-in-out;
  }
  &:hover {
    .icon {
      fill: $black;
    }
  }
}

.top-slide {
  width: rem-calc(60);
  height: rem-calc(60);
  border-radius: 50%;
  background-color: $white;
  position: fixed;
  right: rem-calc(50);
  bottom: rem-calc(40);
  z-index: 1;
  cursor: pointer;
  box-shadow: $boxshadow;
  padding-left: 1px;
  @include breakpoint(small down) {
    display: none;
  }
  .icon {
    fill: $red;
    transform: rotate(180deg);
    transition: all .15s ease-in-out;
  }
  &:hover {
    .icon {
      fill: $black;
    }
  }
}

.form-btn {
  background-color: $black;
  &:hover {
    background-color: $red;
  }
  @include breakpoint(medium) {
    margin-top: 125px;
  }
}

.btn-black {
  background-color: $black;
  &:hover {
    background-color: $red;
  }
}

.close-button {
  img {
    width: 50px;
    @include breakpoint(medium) {
      width: 80px;
    }
  }
}

.btn-margin {
  margin: 3em 0 2em 0;
}


.hero-btn {
  position: absolute;
  z-index: 8;
  bottom: 0;
  left: 20px;
  width: rem-calc(120);
  height: auto;
  @include breakpoint(medium) {
    bottom: auto;
    left: auto;
    top: 20px;
    right: 40px;
    width: rem-calc(180);
  }
  @include breakpoint(large) {
    right: 50px;
    width: rem-calc(220);
  }
}