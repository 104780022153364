.map-box {
  position: relative;
  aspect-ratio: 1;
  border: white;
  border-radius: 1em;
  overflow: hidden;
  &.margin-right {
    @include breakpoint(large) {
      margin-right: rem-calc(30);
    }
  }
  &.margin-left {
    @include breakpoint(large) {
      margin-left: rem-calc(30);
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  .info {
    position: absolute;
    top: rem-calc(20);
    right: rem-calc(30);
    z-index: 2;
    cursor: pointer;
    width: rem-calc(60);
    height: rem-calc(60);
    filter: drop-shadow($boxshadow);
  }
  .overlay {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $white;
    color: $red;
    visibility: hidden;
    transition: all .2s ease-in-out;
    &.show {
      visibility: visible;
      z-index:3;
    }
    .close {
      position: absolute;
      top: rem-calc(25);
      right: rem-calc(35);
      cursor: pointer;
      width: rem-calc(46);
      height: rem-calc(46);
    }

    .map-overlay-content {
      padding: rem-calc(40);
      h3 {
        margin-top: 0;
        margin-bottom: 1em;
        @include font-size(2.25,3.75);
      }
      p {
        padding-bottom: rem-calc(5);
        margin-bottom: rem-calc(5);
        border-bottom: 2px solid $red;
      }
    }
  }
}

.map-caption {
  margin-top: rem-calc(20);
  font-weight: bold;
  line-height: 1.1;
  display: flex;
  align-items: flex-start;
  span {
    display: block;
  }
  @include font-size(1.25,2.1875);
  .svg-icon {
    margin-right: 0.7em;
    margin-top: -0.2em;
    font-size: 0.7em;
    align-self: self-start;
    .icon {
      fill: $white;
      width: 1.8em;
      height: 1.8em;
    }
  }
  &.margin-left {
    @include breakpoint(large) {
      margin-left: rem-calc(30);
    }
  }
}


