footer {
  background-color: $gray;
  color: $white;
  padding: rem-calc(30 0);
  @include breakpoint(medium) {
    padding: rem-calc(50 0);
  }
  .footer-logo {
    width: rem-calc(200);
    height: auto;
    margin: 0 auto 30px auto;
    display: block;
    @include breakpoint(medium) {
      margin: 0;
    }
  }
  .social {
    margin: rem-calc(10 0 20 0);
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $black;
      .icon {
        fill: $black;
      }
    }

  }

  .icon {
    fill: $white;
  }

  .button {
    background-color: $white;
    color: $gray;
    margin: rem-calc(10 5 30 5);
  }

}
